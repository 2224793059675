// TwapSummary.js
import React from 'react';
import './../../../styles/twapSummary.css';

const TwapSummary = ({ activeTwaps }) => {
    console.log(activeTwaps)
    const calculateTwapStats = () => {
        return activeTwaps.reduce((stats, twap) => {
            const value = typeof twap.value === 'string' ?
                parseFloat(twap.value.replace(/[$,K,M]/g, '')) : twap.value;

            // Multiply by 1000 if K, by 1000000 if M
            const multiplier = twap.value.toString().includes('K') ? 1000
                : twap.value.toString().includes('M') ? 1000000 : 1;

            const actualValue = value * multiplier;
            const progress = parseFloat(twap.progress);
            // const isComplete = progress >= 100;

            if (twap.side === 'BUY') {
                stats.buyTotal += actualValue;
                stats.buyCount++;
                stats.buyComplete += (actualValue * progress) / 100;
                stats.buyPending += actualValue * (100 - progress) / 100;
            } else {
                stats.sellTotal += actualValue;
                stats.sellCount++;
                stats.sellComplete += actualValue * progress / 100;
                stats.sellPending += actualValue * (100 - progress) / 100;
            }
            return stats;
        }, {
            buyTotal: 0,
            sellTotal: 0,
            buyCount: 0,
            sellCount: 0,
            buyComplete: 0,
            buyPending: 0,
            sellComplete: 0,
            sellPending: 0
        });
    };

    const formatValue = (value) => {
        if (value === 0) return '$0.00';
        if (value >= 1000000) {
            return `$${(value / 1000000).toFixed(2)}M`;
        } else if (value >= 1000) {
            return `$${(value / 1000).toFixed(2)}K`;
        }
        return `$${value.toFixed(2)}`;
    };

    const stats = calculateTwapStats();

    return (
        <div className="twap-summary">
            <div className="summary-grid">
                <div className="summary-item">
                    <div className="summary-header">
                        <div className="summary-label">Total Buy TWAPs</div>
                        <div className="count buy-count">{stats.buyCount}</div>
                    </div>
                    <div className="summary-value buy-value">
                        {formatValue(stats.buyTotal)}
                    </div>
                    <div className="progress-section">
                        {stats.buyComplete > 0 && (
                            <div className="status-row">
                                <div className="status-label">Complete</div>
                                <div className="status-value">{formatValue(stats.buyComplete)}</div>
                            </div>
                        )}
                        {stats.buyPending > 0 && (
                            <div className="status-row">
                                <div className="status-label">Pending</div>
                                <div className="status-value">{formatValue(stats.buyPending)}</div>
                            </div>
                        )}
                        <div className="progress-bar">
                            <div
                                className="progress-fill buy-complete"
                                style={{
                                    width: `${(stats.buyComplete / stats.buyTotal) * 100}%`
                                }}
                            />
                            <div
                                className="progress-fill buy-pending"
                                style={{
                                    width: `${(stats.buyPending / stats.buyTotal) * 100}%`
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="summary-item">
                    <div className="summary-header">
                        <div className="summary-label">Total Sell TWAPs</div>
                        <div className="count sell-count">{stats.sellCount}</div>
                    </div>
                    <div className="summary-value sell-value">
                        {formatValue(stats.sellTotal)}
                    </div>
                    <div className="progress-section">
                        {stats.sellComplete > 0 && (
                            <div className="status-row">
                                <div className="status-label">Complete</div>
                                <div className="status-value">{formatValue(stats.sellComplete)}</div>
                            </div>
                        )}
                        {stats.sellPending > 0 && (
                            <div className="status-row">
                                <div className="status-label">Pending</div>
                                <div className="status-value">{formatValue(stats.sellPending)}</div>
                            </div>
                        )}
                        <div className="progress-bar">
                            <div
                                className="progress-fill sell-complete"
                                style={{
                                    width: `${(stats.sellComplete / stats.sellTotal) * 100}%`
                                }}
                            />
                            <div
                                className="progress-fill sell-pending"
                                style={{
                                    width: `${(stats.sellPending / stats.sellTotal) * 100}%`
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwapSummary;