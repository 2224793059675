import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

const ValidatorStakeDistributionPieChart = ({ data }) => {
    // Color gradient from light to dark blue
    const COLORS = [
        '#E6E6FA', // Lightest
        '#D8D8FF',
        '#CACAFF',
        '#ACACFF',
        '#8E8EFF',
        '#7070FF',
        '#5252FF',
        '#3434FF',
        '#1616FF',
        '#0000FF',
        '#0000E6',
        '#0000CC',
        '#0000B3',
        '#00009A',
        '#000080',
        '#002475'  // Darkest
    ];

    const formatValue = (value) => {
        if (typeof value !== 'number') return 'N/A';
        if (value >= 1000000) return `${(value / 1000000).toFixed(4)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(4)}K`;
        return value.toFixed(4);
    };

    const totalStake = data.currentValidatorStakes
        .reduce((sum, v) => sum + v.stake, 0);

    const pieData = data.currentValidatorStakes
        .map(validator => ({
            name: validator.name,
            value: validator.stake,
            percentage: (validator.stake / totalStake) * 100,
            isActive: validator.isActive,
            displayValue: formatValue(validator.stake)
        }))
        .sort((a, b) => b.value - a.value);

    const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, value }) => {
        const RADIAN = Math.PI / 180;
        const radius = outerRadius * 1.4;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        // Only show labels for sections > 1% to avoid clutter
        if (percent < 0.01) return null;

        const isRightSide = x > cx;
        const textAnchor = isRightSide ? 'start' : 'end';
        const labelX = isRightSide ? x + 5 : x - 5;

        // Format the value
        const formattedValue = formatValue(value);
        const percentageFormatted = (percent * 100).toFixed(4);

        return (
            <g>
                <line
                    x1={cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN)}
                    y1={cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN)}
                    x2={x}
                    y2={y}
                    stroke="#002475"
                    strokeWidth={1}
                    opacity={0.5}
                />
                <text
                    x={labelX}
                    y={y - 8}
                    fill="#002475"
                    textAnchor={textAnchor}
                    fontSize={12}
                    fontFamily="var(--app-font-family)"
                >
                    {name}
                </text>
                <text
                    x={labelX}
                    y={y + 8}
                    fill="#002475"
                    textAnchor={textAnchor}
                    fontSize={12}
                    fontFamily="var(--app-font-family)"
                >
                    {`${percentageFormatted}% (${formattedValue} HYPE)`}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div style={{
                    backgroundColor: 'white',
                    padding: '12px',
                    border: '1px solid var(--border-color)',
                    borderRadius: '4px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                    <p style={{
                        color: 'var(--primary-color)',
                        fontWeight: '600',
                        margin: '0 0 8px 0'
                    }}>{data.name}</p>
                    <p style={{ margin: '4px 0' }}>
                        Stake: {data.displayValue} HYPE
                    </p>
                    <p style={{ margin: '4px 0' }}>
                        Share: {data.percentage.toFixed(1)}%
                    </p>
                    <p style={{ margin: '4px 0' }}>
                        Status: {data.isActive ? 'Active' : 'Inactive'}
                    </p>
                </div>
            );
        }
        return null;
    };

    const CustomLegend = ({ payload }) => {
        // Split into two groups: major (>1%) and minor (<1%) stakeholders
        const majorStakes = payload.filter(entry => entry.payload.percentage >= 1);
        const minorStakes = payload.filter(entry => entry.payload.percentage < 1);

        const LegendItem = ({ entry, index }) => (
            <div key={`legend-${index}`} style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '15px',
                marginBottom: '5px',
                minWidth: '200px', // Ensure consistent width
                maxWidth: '250px'  // Limit maximum width
            }}>
                <div style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: entry.color,
                    marginRight: '6px',
                    borderRadius: '2px'
                }} />
                <span style={{
                    fontSize: '11px',
                    color: '#002475',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>
                    {entry.payload.name} ({entry.payload.percentage.toFixed(4)}%)
                </span>
            </div>
        );

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '10px'
            }}>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    marginBottom: '10px'
                }}>
                    {majorStakes.map((entry, index) => (
                        <LegendItem entry={entry} index={index} key={index} />
                    ))}
                </div>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    borderTop: '1px solid #eee',
                    paddingTop: '5px'
                }}>
                    {minorStakes.map((entry, index) => (
                        <LegendItem entry={entry} index={index} key={index} />
                    ))}
                </div>
            </div>
        );
    };


    return (
        <div style={{
            backgroundColor: 'var(--card-background-color)',
            border: '1px solid var(--border-color)',
            borderRadius: '8px',
            padding: '20px',
            // height: '420px'
        }}>
            <h3 style={{
                color: 'var(--primary-color)',
                fontSize: '20px',
                margin: '0 0 15px 0',
                textAlign: 'center',
                fontFamily: 'var(--app-font-family)',
                fontWeight: '500'
            }}>
                Current Stake Distribution
            </h3>
            <div style={{ width: '100%', height: '525px', position: 'relative' }}>
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            data={pieData}
                            cx="50%"
                            cy="40%"
                            labelLine={false}
                            label={CustomLabel}
                            outerRadius={100}
                            innerRadius={50}
                            dataKey="value"
                            paddingAngle={1}
                        >
                            {pieData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                    stroke="#FFFFFF"
                                    strokeWidth={1}
                                />
                            ))}
                        </Pie>
                        <Tooltip content={CustomTooltip} />
                        <Legend
                            content={CustomLegend}
                            verticalAlign="bottom"
                            height={120}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default ValidatorStakeDistributionPieChart;