import React, { useState, useEffect } from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import "./../../styles/hype_staking/staking_dasboard.css"

import api_url from "./../../config/api_url.json";

const base_api_url = api_url.backend_api;

const StakingDashboard = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${base_api_url}/api/hype-staking/metrics`);
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 5 * 60 * 1000); // Refresh every 5 minutes
        return () => clearInterval(intervalId);
    }, []);

    const formatValue = (value) => {
        if (typeof value !== 'number') return 'N/A';
        if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(1)} K`;
        return value.toFixed(1);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')} -${(date.getMonth() + 1).toString().padStart(2, '0')} -${date.getFullYear()} `;
    };

    if (loading) return <div className="staking_metrics_loader">Loading data...</div>;
    if (error) return <div className="staking_metrics_error">Error: {error}</div>;
    if (!data) return null;

    return (
        <div className="staking_dashboard_container">
            <div className="staking_dashboard_grid">
                {/* Chart Section */}
                <div className="staking_chart_section">
                    <h3 className="section_title">Total HYPE Staked</h3>
                    <ResponsiveContainer width="100%" height={380}>
                        <AreaChart data={data.dailyData}>
                            <defs>
                                <linearGradient id="stakingGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="#002475" stopOpacity={0.3} />
                                    <stop offset="100%" stopColor="#002475" stopOpacity={0.1} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" stroke="#002475" opacity={0.1} />
                            <XAxis
                                dataKey="date"
                                tickFormatter={formatDate}
                                angle={-45}
                                textAnchor="end"
                                height={100}
                                tick={{ fontSize: 12 }}
                                tickMargin={20}
                            />
                            <YAxis
                                tickFormatter={(value) => formatValue(value)}
                                tick={{ fontSize: 12 }}
                            />
                            <Tooltip
                                content={({ active, payload, label }) => {
                                    if (active && payload && payload.length) {
                                        return (
                                            <div className="staking_tooltip">
                                                <p className="tooltip_date">{formatDate(label)}</p>
                                                <p className="tooltip_value">
                                                    Staked: {formatValue(payload[0].payload.cumulativeStake)}
                                                </p>
                                            </div>
                                        );
                                    }
                                    return null;
                                }}
                            />
                            <Area
                                type="monotone"
                                dataKey="cumulativeStake"
                                stroke="#002475"
                                fill="url(#stakingGradient)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>

                {/* Cards Section */}
                <div className="staking_cards_section">
                    {/* ASXN Labs Card */}
                    <a
                        href="https://app.hyperliquid.xyz/staking"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            display: 'block',
                            // // width: '100%',
                            // height: '100%'
                        }}
                    >
                        <div className="staking_card asxn_card">

                            <h4>Stake with ASXN</h4>

                            {/* <div className="coming_soon">Coming Soon</div> */}
                        </div>
                    </a>

                    {/* Stats Cards */}
                    <div className="staking_card">
                        <div className="card_label">Current Total Staked</div>
                        <div className="card_value">{formatValue(data.currentTotalStake)}</div>
                    </div>

                    <div className="staking_card">
                        <div className="card_label">Staking Percentage</div>
                        <div className="card_value">{data.stakingPercentage.toFixed(2)}%</div>
                    </div>

                    <div className="staking_card">
                        <div className="card_label">Current APR</div>
                        <div className="card_value">{data.currentYield.toFixed(2)}%</div>
                        {/* <div className="card_value">Coming Soon</div> */}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default StakingDashboard;