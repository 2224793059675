import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
} from 'recharts';

const styles = {
    container: {
        border: '1px solid var(--border-color)',
        borderRadius: '8px',
        padding: '20px',
        backgroundColor: 'white'
    },
    title: {
        textAlign: 'center',
        fontSize: '24px',
        color: 'var(--text-color)',
        marginBottom: '10px',
        fontWeight: 'normal'
    },
    subtitle: {
        fontSize: '14px',
        color: 'var(--text-color)',
        opacity: 0.7,
        marginBottom: '20px',
        textAlign: 'center'
    },
    tooltipContainer: {
        backgroundColor: 'white',
        border: '1px solid var(--border-color)',
        borderRadius: '4px',
        padding: '12px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    tooltipTitle: {
        margin: '0 0 8px 0',
        fontWeight: 'bold',
        color: 'var(--primary-color)',
        borderBottom: '1px solid var(--border-color)',
        paddingBottom: '4px'
    },
    tooltipContent: {
        margin: '4px 0',
        fontSize: '12px',
        color: 'var(--text-color)'
    }
};

const StakeSupplyRatioChart = ({ data }) => {
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    const formatPercentage = (value) => {
        return `${value.toFixed(2)}%`;
    };

    const formatValue = (value) => {
        if (typeof value !== 'number') return 'N/A';
        if (value >= 1000000) return `${(value / 1000000).toFixed(2)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(2)}K`;
        return value.toFixed(2);
    };

    const processData = (timeseriesData) => {
        return timeseriesData.map(item => {
            const supply = item.totalSupply || data.totalSupply;
            const ratio = (item.totalStake / supply) * 100;
            return {
                date: item.date,
                ratio: ratio,
                totalStake: item.totalStake,
                totalSupply: supply
            };
        });
    };

    const chartData = processData(data.stakeSupplyTimeseries);

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Staking Metrics Over Time</h2>
            <div style={styles.subtitle}>
                Total Supply, Total Stake, and Staking Ratio (Total Stake / Total Supply)
            </div>
            <div style={{ height: '300px' }}>
                <ResponsiveContainer>
                    <LineChart
                        data={chartData}
                        margin={{ top: 10, right: 60, left: 80, bottom: 20 }}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3"
                            stroke="rgba(0,0,0,0.1)"
                            horizontal={true}
                            vertical={true}
                        />
                        <XAxis
                            dataKey="date"
                            tickFormatter={formatDate}
                            angle={-45}
                            textAnchor="end"
                            height={80}
                            tick={{ fontSize: 12 }}
                            stroke="rgba(0,0,0,0.3)"
                        />
                        <YAxis
                            yAxisId="left"
                            tickFormatter={formatValue}
                            domain={['auto', 'auto']}
                            tick={{ fontSize: 12 }}
                            stroke="rgba(0,0,0,0.3)"
                            label={{
                                value: 'HYPE Amount',
                                angle: -90,
                                position: 'insideLeft',
                                offset: -60,
                                style: { textAnchor: 'middle' }
                            }}
                        />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            tickFormatter={formatPercentage}
                            domain={[0, 100]}
                            tick={{ fontSize: 12 }}
                            stroke="rgba(0,0,0,0.3)"
                            label={{
                                value: 'Staking Ratio',
                                angle: 90,
                                position: 'insideRight',
                                offset: -30,
                                style: { textAnchor: 'middle' }
                            }}
                        />
                        <Tooltip
                            content={({ active, payload, label }) => {
                                if (active && payload && payload.length) {
                                    return (
                                        <div style={styles.tooltipContainer}>
                                            <p style={styles.tooltipTitle}>
                                                {formatDate(label)}
                                            </p>
                                            <p style={{
                                                ...styles.tooltipContent,
                                                color: '#4147B8'
                                            }}>
                                                Total Supply: {formatValue(payload[0].value)} HYPE
                                            </p>
                                            <p style={{
                                                ...styles.tooltipContent,
                                                color: '#5C64F5'
                                            }}>
                                                Total Stake: {formatValue(payload[1].value)} HYPE
                                            </p>
                                            <p style={{
                                                ...styles.tooltipContent,
                                                color: 'var(--primary-color)'
                                            }}>
                                                Staking Ratio: {formatPercentage(payload[2].value)}
                                            </p>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Legend
                            verticalAlign="top"
                            height={36}
                        />
                        <Line
                            yAxisId="left"
                            type="monotone"
                            dataKey="totalSupply"
                            name="Total Supply"
                            stroke="#4147B8"
                            strokeWidth={2}
                            dot={{
                                stroke: '#4147B8',
                                strokeWidth: 2,
                                r: 4,
                                fill: 'white'
                            }}
                        />
                        <Line
                            yAxisId="left"
                            type="monotone"
                            dataKey="totalStake"
                            name="Total Stake"
                            stroke="#5C64F5"
                            strokeWidth={2}
                            dot={{
                                stroke: '#5C64F5',
                                strokeWidth: 2,
                                r: 4,
                                fill: 'white'
                            }}
                        />
                        <Line
                            yAxisId="right"
                            type="monotone"
                            dataKey="ratio"
                            name="Staking Ratio"
                            stroke="var(--primary-color)"
                            strokeWidth={2}
                            dot={{
                                stroke: 'var(--primary-color)',
                                strokeWidth: 2,
                                r: 4,
                                fill: 'white'
                            }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default StakeSupplyRatioChart;