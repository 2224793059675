import React from 'react';
import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

const styles = {
    container: {
        padding: '20px',
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        fontFamily: 'var(--app-font-family)',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        margin: '10px'
    },
    title: {
        textAlign: 'center',
        fontSize: '20px',
        color: 'var(--text-color)',
        marginBottom: '20px',
        fontWeight: 'normal',
        fontFamily: 'var(--app-font-family)'
    },
    tooltipContainer: {
        backgroundColor: 'var(--card-background-color)',
        border: '1px solid var(--border-color)',
        borderRadius: '4px',
        padding: '12px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    tooltipTitle: {
        margin: '0 0 8px 0',
        fontWeight: 'bold',
        color: 'var(--text-color)',
        borderBottom: '1px solid var(--border-color)',
        paddingBottom: '4px'
    },
    tooltipContent: {
        margin: '4px 0',
        fontSize: '12px',
        color: 'var(--text-color)'
    }
};

const UndelegationChart = ({ data }) => {
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    };

    const formatValue = (value) => {
        if (typeof value !== 'number') return 'N/A';
        if (value >= 1000000) return `${(value / 1000000).toFixed(3)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
        return value.toFixed(2);
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Pending Undelegations (HYPE)</h2>
            <div style={{ height: '400px', width: '100%' }}>
                <ResponsiveContainer>
                    <ComposedChart
                        data={data}
                        margin={{ top: 20, right: 80, left: 80, bottom: 20 }}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3"
                            stroke="rgba(0,0,0,0.1)"
                            horizontal={true}
                            vertical={false}
                        />
                        <XAxis
                            dataKey="date"
                            tickFormatter={formatDate}
                            tick={{ fill: 'var(--text-color)', fontSize: 12 }}
                            axisLine={{ stroke: 'var(--border-color)' }}
                            angle={-45}
                            textAnchor="end"
                            height={80}
                        />
                        <YAxis
                            yAxisId="left"
                            tickFormatter={formatValue}
                            tick={{ fill: 'var(--text-color)', fontSize: 12 }}
                            axisLine={{ stroke: 'var(--border-color)' }}
                            label={{
                                value: 'Undelegation Amount',
                                angle: -90,
                                position: 'insideLeft',
                                offset: -60,
                                style: { textAnchor: 'middle' }
                            }}
                        />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            tickFormatter={formatValue}
                            tick={{ fill: 'var(--text-color)', fontSize: 12 }}
                            axisLine={{ stroke: 'var(--border-color)' }}
                            label={{
                                value: 'Cumulative Amount',
                                angle: 90,
                                position: 'insideRight',
                                offset: -50,
                                style: { textAnchor: 'middle' }
                            }}
                        />
                        <Tooltip
                            content={({ active, payload, label }) => {
                                if (active && payload && payload.length) {
                                    return (
                                        <div style={styles.tooltipContainer}>
                                            <p style={styles.tooltipTitle}>
                                                {formatDate(label)}
                                            </p>
                                            <p style={{
                                                ...styles.tooltipContent,
                                                color: 'var(--primary-color)'
                                            }}>
                                                Undelegation Amount: {formatValue(payload[0].value)} HYPE
                                            </p>
                                            <p style={{
                                                ...styles.tooltipContent,
                                                color: '#5c6ac4'
                                            }}>
                                                Cumulative Amount: {formatValue(payload[1].value)} HYPE
                                            </p>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Legend
                            verticalAlign="top"
                            height={36}
                            wrapperStyle={{ fontFamily: 'var(--app-font-family)' }}
                        />
                        <Bar
                            yAxisId="left"
                            dataKey="undelegationAmount"
                            name="Undelegation Amount"
                            fill="var(--primary-color)"
                            opacity={0.8}
                        />
                        <Line
                            yAxisId="right"
                            type="monotone"
                            dataKey="cumulativeUndelegationAmount"
                            name="Cumulative Amount"
                            stroke="#5c6ac4"
                            strokeWidth={2}
                            dot={{
                                r: 4,
                                fill: '#5c6ac4',
                                stroke: '#5c6ac4'
                            }}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default UndelegationChart;