import React, { useState, useEffect } from 'react';
import AllValidatorTable from './allValidatorTable';
import ValidatorCharts from './ValidatorCharts';
import RecentBlocksChart from './RecentBlocksChart';
import StakingDashboard from './hype_staking_cummulative_chart_and_details';
import TotalValidatorMetricsPieChart from './totalValidatorMetricsPieChart';
import ValidatorStakeTimeSeriesChart from './validatorStakeTimeSeriesChart';
import ValidatorStakeDistributionPieChart from './validatorStakeDistributionPieChart';
import StakeSupplyRatioChart from './stakehypeVStotalsupplychart';
import UndelegationChart from './UndelegationChart';

import "./../../styles/hype_staking/validator_table.css";


import api_url from "./../../config/api_url.json";



const base_api_url = api_url.backend_api;
const promotheous_api_url = api_url.promotheous_api
const hyperliquid_mainnet_api_url = api_url.hyperliquid_mainnet_api_url

const HypeStakingComponent = () => {
    const [data, setData] = useState([]);
    const [blockHeight, setBlockHeight] = useState(null);
    const [unstakingQueueData, setUnstakingQueueData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [validatorMetricsData, setValidatorMetricsData] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${base_api_url}/api/hype-staking/metrics`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const jsonData = await response.json();
                setValidatorMetricsData(jsonData);
                setError(null);
            } catch (err) {
                console.error('Error fetching validator data:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 5 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const fetchUnstakingQueueData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${base_api_url}/api/proxy/unstakingQueue?days=8`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const jsonData = await response.json();
                setUnstakingQueueData(jsonData);
                setError(null);
            } catch (err) {
                console.error('Error fetching validator data:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUnstakingQueueData();
        const intervalId = setInterval(fetchUnstakingQueueData, 5 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(hyperliquid_mainnet_api_url + '/info', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        type: 'validatorSummaries'
                    })
                });

                const result = await response.json();
                setData(result);
            } catch (err) {
                // setError('Error fetching validator data');
                setError('Validator API is down');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        fetchBlockHeight();
    }, []);

    const fetchBlockHeight = async () => {
        try {
            const response = await fetch(`${promotheous_api_url}/api/v1/query?query=mainnet_block_height`);
            const result = await response.json();
            if (result.status === "success" && result.data.result[0]?.value[1]) {
                setBlockHeight(parseInt(result.data.result[0].value[1]).toLocaleString());
            }
        } catch (error) {
            console.error('Error fetching block height:', error);
        }
    };

    if (loading) return <div className="validator_metrics_loader_card">Loading data...</div>;
    // if (error) return <div className="validator_metrics_error_card">Error: {error}</div>;
    if (error) return <div className="validator_metrics_error_card">{error}</div>;

    const totalStake = data.reduce((sum, v) => sum + v.stake, 0);

    if (!data || !validatorMetricsData || !unstakingQueueData) {
        return (
            <div>
                <div className="validator_metrics_loader_card">Loading data...</div>;
            </div>
        );
    }

    return (
        <>
            <div className="validator_dashBoard_head">
                <div className="validator_wallet_note">
                    <h4>
                        Validator statistics and performance metrics for the Hyperliquid network
                    </h4>
                </div>
                <StakingDashboard />
                <br />
                <br />

                <ValidatorStakeTimeSeriesChart data={validatorMetricsData} />
                <br />
                <br />
                <StakeSupplyRatioChart data={validatorMetricsData} />
                <br />
                <br />
                <UndelegationChart data={unstakingQueueData.processed_data} />
                <br />
                <br />
                <div className="pie_charts_container">
                    <div className="pie_chart_left">
                        <ValidatorStakeDistributionPieChart data={validatorMetricsData} />
                    </div>
                    <div className="pie_chart_right">
                        <TotalValidatorMetricsPieChart data={data} blockHeight={blockHeight} />
                    </div>
                </div>
                <br />

                {/* Main validator section */}
                <div className="validator_section">
                    <AllValidatorTable validator_summary_data={data} />
                </div>
                <br />
                <br />
                <br />
                <div className='all_validators_charts_main_grid'>
                    <ValidatorCharts data={data} total_stake={totalStake} />
                    <RecentBlocksChart data={data} />
                </div>
                <div className="data-source">Source: Hyperliquid & Hypurrscan</div>
                <br />
            </div>
        </>
    );
};

export default HypeStakingComponent;