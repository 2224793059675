import React, { useState, useEffect } from 'react';
import { ChevronUp, ChevronDown, Copy, Check } from 'lucide-react';
import './../../styles/hype_staking/alllValidatorTable.css';
const AllValidatorTable = ({ validator_summary_data }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({
        key: 'name',
        direction: 'asc'
    });
    const [copiedAddress, setCopiedAddress] = useState(null);

    const formatPercentage = (value) => {
        return `${(parseFloat(value) * 100).toFixed(2)}%`;
    };

    const getUptime = (stats, period) => {
        const periodStats = stats?.find(([p]) => p === period);
        return periodStats ? parseFloat(periodStats[1].uptimeFraction) : 0;
    };

    const getAPR = (stats) => {
        const dailyStats = stats?.find(([p]) => p === 'month');
        return dailyStats ? parseFloat(dailyStats[1].predictedApr) : 0;
    };

    const handleCopy = async (address) => {
        try {
            await navigator.clipboard.writeText(address);
            setCopiedAddress(address);
            setTimeout(() => setCopiedAddress(null), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    useEffect(() => {
        const sortedData = [...validator_summary_data].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        setData(sortedData);
        setLoading(false);
    }, [validator_summary_data]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key) {
            direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        }
        setSortConfig({ key, direction });
    };

    const sortData = (data, sortConfig) => {
        if (!sortConfig.key) return data;

        return [...data].sort((a, b) => {
            let aValue, bValue;
            let multiplier = sortConfig.direction === 'asc' ? 1 : -1;

            switch (sortConfig.key) {
                case 'dayUptime':
                case 'weekUptime':
                case 'monthUptime': {
                    const period = sortConfig.key.replace('Uptime', '');
                    aValue = getUptime(a.stats, period);
                    bValue = getUptime(b.stats, period);

                    // Prioritize ASXN for equal values
                    if (Math.abs(aValue - bValue) < 0.0001) {
                        if (a.name === 'ASXN') return 1 * multiplier;
                        if (b.name === 'ASXN') return -1 * multiplier;
                    }
                    break;
                }
                case 'validator':
                    // Sort by validator address
                    return (a.validator || '').localeCompare(b.validator || '') * multiplier;
                case 'apr':
                    aValue = getAPR(a.stats);
                    bValue = getAPR(b.stats);
                    break;
                case 'stake':
                    aValue = parseFloat(a.stake);
                    bValue = parseFloat(b.stake);
                    break;
                case 'commission':
                    aValue = parseFloat(a.commission);
                    bValue = parseFloat(b.commission);
                    break;
                case 'status':
                    aValue = a.isJailed ? 1 : 0;
                    bValue = b.isJailed ? 1 : 0;
                    break;
                case 'name':
                    return a.name.localeCompare(b.name) * multiplier;
                default:
                    aValue = a[sortConfig.key];
                    bValue = b[sortConfig.key];
            }

            if (aValue < bValue) return -1 * multiplier;
            if (aValue > bValue) return 1 * multiplier;

            // Secondary sort by name
            if (!['dayUptime', 'weekUptime', 'monthUptime'].includes(sortConfig.key)) {
                return a.name.localeCompare(b.name);
            }
            return 0;
        });
    };

    const formatAddress = (address) => {
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };

    const formatStake = (stake) => {
        const value = stake / 1e8;
        if (value >= 1e9) return `${(value / 1e9).toFixed(2)}B`;
        if (value >= 1e6) return `${(value / 1e6).toFixed(2)}M`;
        if (value >= 1e3) return `${(value / 1e3).toFixed(2)}K`;
        return value.toFixed(2);
    };

    const CopyButton = ({ address }) => (
        <button
            onClick={() => handleCopy(address)}
            className={`vd_copy_button ${copiedAddress === address ? 'copied' : ''}`}
        >
            {copiedAddress === address ? <Check size={16} /> : <Copy size={16} />}
        </button>
    );

    const SortIcon = ({ columnKey }) => (
        <span className={`vd_sort_icon ${sortConfig.key === columnKey ? sortConfig.direction : ''}`}>
            <div className="vd_sort_arrows">
                <ChevronUp className="up" size={14} />
                <ChevronDown className="down" size={14} />
            </div>
        </span>
    );

    const columns = [
        { key: 'name', topLine: 'Name', bottomLine: '' },
        { key: 'stake', topLine: 'Stake', bottomLine: '' },
        { key: 'validator', topLine: 'Validator', bottomLine: 'Address' },
        { key: 'dayUptime', topLine: 'Daily', bottomLine: 'Uptime' },
        { key: 'weekUptime', topLine: 'Weekly', bottomLine: 'Uptime' },
        { key: 'monthUptime', topLine: 'Monthly', bottomLine: 'Uptime' },
        { key: 'commission', topLine: 'Commission', bottomLine: '' },
        { key: 'apr', topLine: 'APR', bottomLine: '' },
        { key: 'nRecentBlocks', topLine: 'Recent', bottomLine: 'Blocks' },
        { key: 'status', topLine: 'Status', bottomLine: '' }
    ];

    if (loading) return <div className="validator_metrics_loader_card">Loading data...</div>;

    const sortedData = sortData(data, sortConfig);

    return (
        <div className="vd_dashboard_container">
            <div className="vd_table_wrapper">
                <table className="vd_validator_table">
                    <thead>
                        <tr>
                            {columns.map((column) => (
                                <th
                                    key={column.key}
                                    onClick={() => requestSort(column.key)}
                                    className="vd_header_cell"
                                >
                                    <div className="vd_header_content">
                                        <div className="vd_header_text">
                                            <span>{column.topLine}</span>
                                            {column.bottomLine && (
                                                <span className="bottom-line">{column.bottomLine}</span>
                                            )}
                                        </div>
                                        <SortIcon columnKey={column.key} />
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((item) => (
                            <tr key={item.validator}>
                                <td>{item.name}</td>
                                <td>
                                    <span>{formatStake(item.stake)}</span>
                                </td>
                                <td>
                                    <div className="vd_address_cell">
                                        <span>{formatAddress(item.validator)}</span>
                                        <CopyButton address={item.validator} />
                                    </div>
                                </td>
                                <td>{formatPercentage(getUptime(item.stats, 'day'))}</td>
                                <td>{formatPercentage(getUptime(item.stats, 'week'))}</td>
                                <td>{formatPercentage(getUptime(item.stats, 'month'))}</td>
                                <td>{formatPercentage(item.commission)}</td>
                                <td>{formatPercentage(getAPR(item.stats))}</td>
                                <td>{item.nRecentBlocks}</td>
                                <td className={`status-cell ${item.isJailed ? 'status-jailed' : 'status-active'}`}>
                                    {item.isJailed ? 'Jailed' : 'Active'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AllValidatorTable;