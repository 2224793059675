import React, { useState } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Cell
} from 'recharts';
import { formatLargeNumber } from '../utils/utils';

const ValidatorCharts = ({ data, total_stake }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    // Theme colors
    const THEME = {
        primary: '#002475',
        primaryLight: '#003399', // Lighter shade for hover
        text: '#333',
        background: '#f0f0f0',
        border: '#002475',
        cardBackground: 'white'
    };

    const sortedTop50 = [...data]
        .sort((a, b) => b.stake - a.stake)
        .slice(0, 40);

    const barChartData = sortedTop50.map(item => ({
        name: item.name || item.validator.slice(0, 6),
        stake: item.stake / 1e8,
        originalStake: item.stake
    })).sort((a, b) => b.stake - a.stake);

    const formatYAxis = (value) => {
        if (value === 0) return '0';
        if (Math.abs(value) >= 1e6) return (value / 1e6).toFixed(0) + 'M';
        if (Math.abs(value) >= 1e3) return (value / 1e3).toFixed(0) + 'K';
        return value.toFixed(0);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{
                    backgroundColor: THEME.cardBackground,
                    border: `1px solid ${THEME.border}`,
                    borderRadius: '4px',
                    padding: '12px',
                    maxWidth: window.innerWidth < 640 ? '150px' : '200px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                }}>
                    <p style={{
                        margin: '0',
                        color: THEME.text,
                        fontSize: '12px',
                        fontWeight: 'bold',
                        wordBreak: 'break-word'
                    }}>{label}</p>
                    <p style={{
                        margin: '4px 0 0 0',
                        color: THEME.primary,
                        fontSize: '12px'
                    }}>
                        {`Stake: ${payload[0].payload.stake.toLocaleString()}`}
                    </p>
                </div>
            );
        }
        return null;
    };

    const CustomLegend = () => (
        <div className="chart-legend">
            <span style={{
                display: 'inline-block',
                width: '12px',
                height: '12px',
                backgroundColor: THEME.primary,
                marginRight: '6px'
            }}></span>
            <span style={{ color: THEME.text }}>
                Total_Stake : {formatLargeNumber(total_stake / 1e8)}
            </span>
        </div>
    );

    const chartConfig = {
        margin: {
            top: 20,
            right: 10,
            bottom: 100,
            left: 40
        },
        xAxisConfig: {
            angle: window.innerWidth < 640 ? -45 : -30,
            textAnchor: "end",
            interval: window.innerWidth < 640 ? 1 : 0,
            height: 50,
            tick: {
                fill: THEME.text,
                fontSize: window.innerWidth < 640 ? 6 : 8,
                dy: 8
            }
        },
        yAxisConfig: {
            tick: {
                fill: THEME.text,
                fontSize: 10
            },
            width: 45
        }
    };

    const handleMouseEnter = (index) => setActiveIndex(index);
    const handleMouseLeave = () => setActiveIndex(null);

    return (
        <div className="all_validators_chart-container">
            <h3 style={{
                color: THEME.text,
                marginBottom: '20px',
                fontSize: '14px',
                fontWeight: 'normal',
                textAlign: 'center',
            }}>
                Validator Stake Distribution
            </h3>
            <CustomLegend />
            <div style={{ width: '100%', height: 'calc(100% - 80px)', marginTop: '30px' }}>
                <ResponsiveContainer>
                    <BarChart
                        data={barChartData}
                        margin={chartConfig.margin}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3"
                            vertical={false}
                            stroke={`${THEME.primary}20`}
                        />
                        <XAxis
                            dataKey="name"
                            {...chartConfig.xAxisConfig}
                        />
                        <YAxis
                            {...chartConfig.yAxisConfig}
                            tickFormatter={formatYAxis}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar
                            dataKey="stake"
                            onMouseLeave={handleMouseLeave}
                        >
                            {barChartData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={index === activeIndex ? THEME.primaryLight : THEME.primary}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default ValidatorCharts;