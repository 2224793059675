import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import "./../../styles/hype_staking/totalValidatorMetricsPieChart.css"


const TotalValidatorMetricsPieChart = ({ data, blockHeight }) => {
    const COLORS = {
        active: '#002475',
        jailed: '#CC3333'
    };

    const pieData = [
        {
            name: 'Active',
            value: data.filter(v => !v.isJailed).length,
            color: COLORS.active,
            label: `Active: ${data.filter(v => !v.isJailed).length}`
        },
        {
            name: 'Jailed',
            value: data.filter(v => v.isJailed).length,
            color: COLORS.jailed,
            label: `Jailed: ${data.filter(v => v.isJailed).length}`
        }
    ];

    // Custom label component
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        value,
        name
    }) => {
        const RADIAN = Math.PI / 180;
        const radius = outerRadius * 1.2;  // Position label outside the pie
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="var(--text-color)"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
                style={{ fontSize: '14px', fontFamily: 'var(--app-font-family)' }}
            >
                {`${name}: ${value}`}
            </text>
        );
    };

    return (
        <div className="validator_metrics_container">
            <div className="metrics_content">
                <div className="metrics_center_container">
                    <div className="validator_metrics_header">
                        <h3>Current Block Height</h3>
                        <div className="block_height_value">{blockHeight || 'Loading...'}</div>
                    </div>

                    <div className="recharts-responsive-container">
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={pieData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    innerRadius={40}
                                    stroke="var(--border-color)"
                                    labelLine={true}
                                    label={renderCustomizedLabel}
                                >
                                    {pieData.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.color}
                                        />
                                    ))}
                                </Pie>
                                <Legend
                                    verticalAlign="bottom"
                                    align="center"
                                    formatter={(value, entry) => {
                                        if (value === 'Active') return `Active: ${entry.payload.value}`;
                                        return `Jailed: ${entry.payload.value}`;
                                    }}
                                    wrapperStyle={{
                                        fontSize: '14px',
                                        paddingTop: '20px',
                                        color: 'var(--text-color)'
                                    }}
                                />
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>

                    <div className="validator_metrics_footer">
                        <div className="total_validators">
                            Total Validators: {data.length}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TotalValidatorMetricsPieChart;